import { toast } from "sonner";
import axios from "axios";
import queryString from "query-string";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  paramsSerializer(params) {
    return queryString.stringify(params, { indices: false });
  },
});

// Request Interceptors
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptors
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.success) {
      if (response.config.method !== "get" && (response.data.message || response.data.info)) {
        toast.success(response.data.message || response.data.info);
      }
      return response;
    } else if (!response.data.success) {
      toast.error(response.data.message || response.data.info);
      if (response.data.info === "Session has expired. Please login again. Error Code L105") {
        localStorage.clear();
        if (window.location.pathname === "/onboarding/auth" || window.location.pathname === "/onboarding/sign-in" || window.location.pathname === "/auth/sign-in") {
          return;
        } else {
          window.location = "/onboarding/sign-in";
        }
      }

      return response;
    } else {
      const messages = response.data.messages;
      if (messages) {
        if (messages instanceof Array) {
          return Promise.reject({ messages });
        }
        return Promise.reject({ messages: [messages] });
      }
      return Promise.reject({ messages: ["got errors"] });
    }
  },
  (error) => {
    //unauthorised error
    toast.error(error.response.data.message);
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      if (window.location.pathname === "/onboarding/auth" || window.location.pathname === "/onboarding/sign-in" || window.location.pathname === "/auth/sign-in") {
        return;
      } else {
        window.location = "/onboarding/sign-in";
      }
    }
    //internal server error
    else if (error.response && error.response.status === 500) {
      return Promise.reject(error.response);
    }
    //any other error
    else return Promise.reject(error);
  }
);

export default axiosInstance;
