import axiosInstance from "../../Util/config";

export function postReq(params) {
  const { route, payload } = params;
  return axiosInstance.post(`${route}`, payload).then((res) => res).catch((err)=> {throw err});
}

export function patchReq(params) {
  const { route, payload } = params;
  return axiosInstance.patch(`${route}`, payload).then((res) => res).catch((err)=> {throw err});
}

export function puthReq(params) {
  const { route, payload } = params;
  return axiosInstance.put(`${route}`, payload).then((res) => res).catch((err)=> {throw err});
}

export function deleteReq(params) {
  const { route, payload } = params;
  return axiosInstance.delete(`${route}`, { data: payload }).then((res) => res).catch((err)=> {throw err});
}
